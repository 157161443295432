<template lang="html">
  <div>
    <!-- Logo -->
    <v-row class="justify-center my-4">
      <v-col cols="6" sm="5" md="3" lg="2" class="text-center">
         <img src="concorde-logo-new-white.png" />
      </v-col>
    </v-row>
    <!-- Email input -->
    <client-only>
      <v-row class="justify-center mb-5">
        <v-col cols="12" sm="8" md="6" lg="5">
          <v-card>
            <v-card-title>
              <v-row class="justify-center">
                <v-col cols="12" class="text-center">
                  <h2>Welcome</h2>
                  <div class="subtitle-2 mt-2">Please enter your email address to begin</div>
                  <v-divider class="mt-3"></v-divider>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters class="justify-center">
                <v-col cols="12" class="text-center">
                  <v-text-field
                    ref="email"
                    v-model.trim="email"
                    outlined
                    clearable
                    name="email"
                    label="Email Address"
                    hint="Enter your email address"
                    @click:clear="resetLoginFlow()"
                    @keyup.enter="loginPreflight()"
                    @keydown.tab="loginPreflight()"
                    @input="lowercaseEmail()"
                  />
                  <v-btn v-if="!authType" :loading="loading" large color="primary" @click="loginPreflight()">
                    Continue
                  </v-btn>
                </v-col>
                <v-slide-y-transition>
                  <v-col v-if="authType === 'login'" cols="12" class="text-center">
                    <v-text-field
                      ref="password"
                      v-model.trim="password"
                      outlined
                      clearable
                      name="password"
                      label="Password"
                      type="password"
                      hint="Enter the password for your account"
                      @keyup.enter="login()"
                    />
                    <p></p>
                  </v-col>
                </v-slide-y-transition>
                <v-col v-if="authType === 'signup'" cols="12">
                  <v-alert type="info" text>
                    It looks like this is the first time you have used our online service. To get started,
                    lets set a password for your account.
                  </v-alert>
                  <v-col cols="12" class="text-center d-flex flex-column align-center justify-center">
                    <v-btn :loading="loading" large color="primary" @click="goToSignup()">
                      Continue <v-icon right>mdi-chevron-right-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
                <v-col
                  v-if="authType === 'login'"
                  cols="12"
                  class="text-center d-flex flex-column align-center justify-center"
                >
                  <v-btn
                    :disabled="disableLogin"
                    :loading="loading"
                    large
                    color="primary"
                    @click.stop="handleAuth()"
                  >
                    <!-- {{ authType === 'login' ? 'Log In' : 'Sign Up' }} -->
                    Log In
                  </v-btn>
                </v-col>
                <v-col v-if="authType === 'invalid'" cols="12">
                  <v-alert type="error">
                    The email address you entered was not found in our system. Please check that the email
                    address you entered is the same one you provided when registering. If you want to use a
                    different email address, please get in touch with us.
                  </v-alert>
                  <v-col cols="12" class="text-center d-flex flex-column align-center justify-center">
                    <v-btn :loading="loading" large color="primary" @click="resetLoginFlow()">
                      Start again <v-icon right>mdi-restart</v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
                <v-col v-show="showRecaptcha" cols="12">
                  <div id="recaptcha"></div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </client-only>

    <v-row class="justify-center mt-5">
      <v-col cols="12" class="text-center">
        <v-btn nuxt link outlined small class="white--text" to="/reset-password">
          Forgot password?
        </v-btn>
        <p class="mt-5 white--text">
          <nuxt-link class="mt-5 white--text" to="/sign-up">
            Don't have an account yet?
          </nuxt-link>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  layout: 'default',
  data: () => ({
    loading: false,
    email: null,
    emailDoesNotExist: false,
    noAccountYet: false,
    password: null,
    showRecaptcha: false,
    disableLogin: false,
    authType: null
  }),
  computed: {
    passStrengthVal() {
      const { password } = this
      return (100 / 6) * ((password && password.length) || 0)
    },
    passStrengthColor() {
      const { passStrengthVal } = this
      return passStrengthVal > 80 ? 'green' : passStrengthVal > 40 ? 'orange' : 'red'
    }
  },
  watch: {
    authType(val) {
      if (!val) {
        this.email = null
        this.password = null
      }
    }
  },
  methods: {
    async handleAuth() {
      const { authType } = this
      await this[authType]()
    },
    // async signup() {
    //   try {
    //     let { email, password } = this
    //     if (!email) {
    //       return this.$snack('Email should not be blank!')
    //     }
    //     if (!password) {
    //       return this.$snack('Password should not be blank!')
    //     }
    //     this.loading = true
    //     email.toLowerCase()
    //     await this.$user.signup(email, password)
    //     this.$router.push('/set-up-account')
    //     // set timeout here as the redirect is handled by auth watcher and can be delayed.
    //     setTimeout(() => {
    //       this.loading = false
    //     }, 1000)
    //   } catch (e) {
    //     this.loading = false
    //     if (e.code === 'error/email-does-not-exist') {
    //       this.emailDoesNotExist = true
    //     } else {
    //       this.$handleError(e)
    //     }
    //   }
    // },
    goToSignup() {
      // Push the user to the signup route, passing over their email address to save them re-entering it
      const { email } = this
      this.$router.push({ path: '/sign-up', query: { email } })
    },
    async login() {
      try {
        let { email, password } = this
        if (!email) {
          return this.$snack.info('Please enter your email address')
        }
        if (!password) {
          return this.$snack.info('Please enter your password')
        }
        email.toLowerCase()
        this.loading = true
        await this.$user.login(email, password)
        if (this.$route.query && this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          const claims = await this.$user.getCustomClaims()
          this.$routeHelper.redirectHome({ claimsObject: claims })
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        console.log('Login error code', e.code)
        if (e && e.code === 'auth/too-many-requests' && process.browser) {
          // Recaptcha does seem to be available in the firebase node sdk
          // const { $firebaseApp } = this
          // this.disableLogin = true
          // this.showRecaptcha = true
          // window.recaptchaVerifier = new $firebaseApp.auth.RecaptchaVerifier('recaptcha', {
          //   callback: response => {
          //     console.log('recaptcha res', response)
          //     this.disableLogin = false
          //   },
          //   'expired-callback': () => {
          //     this.$snack.error('reCaptcha expired, please try again')
          //   }
          // })
          // window.recaptchaVerifier.render()
          return this.$snack.error(
            'Too many attempts with wrong password. Please try again later or reset your password.'
          )
        }
        this.$handleError(e)
      }
    },
    async loginPreflight() {
      try {
        const { email } = this
        this.loading = true
        const { data } = await this.$axios.post('/api/user/login-preflight', {
          email
        })

        const { permitted, active } = data

        // If user is active and permitted, they should be able to login
        if (permitted && active) {
          this.authType = 'login'
        }

        // If permitted but not active, they need to sign up
        if (permitted && !active) {
          this.authType = 'signup'
        }

        // Otherwise the user isn't permitted
        if (!permitted && !active) {
          this.authType = 'invalid'
        }

        this.$nextTick(() => {
          if (this.authType === 'login') {
            this.$refs.password.focus()
          }
        })
      } catch (e) {
        this.$handleError(e)
      } finally {
        this.loading = false
      }
    },
    async lowercaseEmail() {
      if (this.email) {
        this.email = this.email.toLowerCase()
      }
    },
    async resetLoginFlow() {
      this.password = ''
      this.email = ''
      this.authType = null
      this.$nextTick(() => {
        this.$refs.email.focus()
      })
    }
  }
}
</script>

<style lang="css"></style>
